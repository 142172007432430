import CheckIcon from '@mui/icons-material/Check';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import {Theme} from '@mui/material';
import colors from '../colors';

/**
 * todo boxShadows have a theme setting
 *
 * Notes:
 *    avoid using colors const and hardcoded colors to allow changing themes
 *    color cannot be a css variable but otherwise try to use css variables
 *
 */
export const alertsTheme = (themeSettings: Theme): Theme['components'] => ({
  MuiAlert: {
    defaultProps: {
      color: 'secondary' as any,
      severity: 'info',
      iconMapping: {
        error: <InfoIcon />,
        info: <InfoIcon />,
        success: <CheckIcon />,
        warning: <WarningIcon />,
      },
    },

    styleOverrides: {
      root: {
        backgroundColor: themeSettings.palette.background.paper,

        '& .MuiAlert-message': {
          color: themeSettings.palette.text.primary,
          fontSize: 14,
          lineHeight: 1.29,
        },

        '& .MuiAlertTitle-root': {
          fontSize: 18,
          fontWeight: 600,
          lineHeight: 1.17,
        },

        '&.MuiAlert-filled': {
          maxHeight: '40px',
          padding: '0 10px',
          boxShadow:
            '0 8px 24px 0 rgba(16, 22, 26, 0.2), 0 2px 4px 0 rgba(16, 22, 26, 0.2), 0 0 0 1px rgba(16, 22, 26, 0.1)',

          '& *, & * > *': {
            paddingTop: 0,
            lineHeight: '40px',
            minHeight: '40px',
            color: themeSettings.palette.primary.contrastText,
          },
        },

        '&.MuiAlert-filledSecondary': {
          backgroundColor: themeSettings.palette.secondary.contrastText,

          '& *, & * > *': {
            color: themeSettings.palette.secondary.dark,
          },
        },

        '&.MuiAlert-filledPrimary': {
          backgroundColor: themeSettings.palette.primary.dark,
        },

        '&.MuiAlert-filledSuccess': {
          backgroundColor: themeSettings.palette.success.dark,
        },

        '&.MuiAlert-filledWarning': {
          backgroundColor: themeSettings.palette.warning.dark,
        },

        '&.MuiAlert-filledError': {
          backgroundColor: themeSettings.palette.error.dark,
        },

        '&.MuiAlert-outlinedSecondary': {
          backgroundColor: themeSettings.palette.secondary.light,

          '& .MuiAlertTitle-root, & .MuiAlert-icon': {
            color: themeSettings.palette.secondary.main,
          },
        },

        '&.MuiAlert-outlined': {
          padding: '1px 16px',
          border: 0,
        },

        '&.MuiAlert-outlinedPrimary': {
          backgroundColor: themeSettings.palette.primary.light,

          '& .MuiAlertTitle-root, & .MuiAlert-icon': {
            color: themeSettings.palette.primary.main,
          },
        },

        '&.MuiAlert-outlinedSuccess': {
          backgroundColor: themeSettings.palette.success.light,

          '& .MuiAlertTitle-root, & .MuiAlert-icon': {
            color: themeSettings.palette.success.main,
          },
        },

        '&.MuiAlert-outlinedWarning': {
          backgroundColor: themeSettings.palette.warning.light,

          '& .MuiAlertTitle-root, & .MuiAlert-icon': {
            color: themeSettings.palette.warning.main,
          },
        },

        '&.MuiAlert-outlinedError': {
          backgroundColor: themeSettings.palette.error.light,

          '& .MuiAlertTitle-root, & .MuiAlert-icon': {
            color: themeSettings.palette.error.main,
          },
        },
      },
    },
  },

  MuiDialog: {
    styleOverrides: {
      paper: {
        backgroundColor: colors.veryLightBlue,
      },
    },
  },

  MuiTooltip: {
    defaultProps: {
      arrow: true,
    },

    styleOverrides: {
      popper: {
        zIndex: 2000,
        overflow: 'visible',

        /**
         * Using the popper selector bc the mui tooltipPlacementXXX classname
         * is not updated when the popup doesn't fit onscreen and is moved
         * to the opposite placement but popper is correctly updating [data-popper-placement].
         */

        '&[data-popper-placement*=top] .MuiTooltip-tooltip': {
          marginBottom: '4px !important',
        },

        '&[data-popper-placement*=right] .MuiTooltip-tooltip': {
          marginLeft: '4px !important',
        },

        '&[data-popper-placement*=bottom] .MuiTooltip-tooltip': {
          marginTop: '4px  !important',
        },

        '&[data-popper-placement*=left] .MuiTooltip-tooltip': {
          marginRight: '4px  !important',
        },
      },

      tooltip: {
        padding: '6px 10px',
        fontSize: 14,
        lineHeight: 1.29,
        boxShadow:
          '0 8px 24px 0 rgba(16, 22, 26, 0.2), 0 2px 4px 0 rgba(16, 22, 26, 0.2), 0 0 0 1px rgba(16, 22, 26, 0.1)',
        backgroundColor: colors.darkBlueGrey,

        '&.popover': {
          padding: 0,
        },

        '&.more': {
          borderRadius: 3,
          boxShadow:
            '0 8px 24px 0 rgba(16, 22, 26, 0.2), 0 2px 4px 0 rgba(16, 22, 26, 0.2), 0 0 0 1px rgba(16, 22, 26, 0.1)',
          backgroundColor: colors.calcite,
          color: colors.dark,
        },

        '&.more .moreTag': {
          position: 'relative',
          padding: '10px 20px',
        },

        '&.more .moreTag .other': {
          display: 'none',
        },

        '&.more .moreTag .displayName': {
          lineHeight: 2,
          fontWeight: 600,
        },

        '&.more .moreTag .MuiTypography-root': {
          fontSize: 12,
        },

        '&.more .moreTag + .moreTag': {
          borderTop: 'solid 1px #e0e0e0',
        },

        '&.more .moreTag::before': {
          position: 'absolute',
          left: 6,
          top: 18,
          width: 9,
          height: 9,
          borderRadius: '50%',
          border: '1px solid #fff',
          content: '""',
        },

        '&.more .moreTag.PERIOD::before': {
          backgroundColor: colors.darkBlueGrey,
          outline: `1px solid ${colors.darkBlueGrey}`,
        },

        '&.more .moreTag.DEVICE::before': {
          backgroundColor: colors.wear,
          outline: `1px solid ${colors.wear}`,
        },

        '&.more .moreTag.MOBILE::before': {
          backgroundColor: colors.task,
          outline: `1px solid ${colors.task}`,
        },

        '&.more .moreTag.COMPLETED::before': {
          backgroundColor: colors.lightBadges3Success,
          outline: `1px solid ${colors.lightBadges3Success}`,
        },

        '&.more .moreTag.MISSED::before': {
          backgroundColor: colors.lightBadges5Danger,
          outline: `1px solid ${colors.lightBadges5Danger}`,
        },

        '&.more .moreTag.OUTSTANDING::before': {
          backgroundColor: colors.outstanding,
          outline: `1px solid ${colors.outstanding}`,
          opacity: 0.5,
        },

        // tag RESCHEDULED_OUTSTANDING defaults
        '&.more .moreTag.RESCHEDULED_OUTSTANDING::before': {
          backgroundColor: colors.outstanding,
          outline: `1px solid ${colors.outstanding}`,
          opacity: 0.5,
        },
        '&.more .moreTag.RESCHEDULED::before': {
          backgroundColor: colors.nonCompliant,
          outline: `1px solid ${colors.nonCompliant}`,
          opacity: 0.5,
        },

        // tag RESCHEDULED_COMPLETED defaults
        '&.more .moreTag.RESCHEDULED_COMPLETED::before': {
          backgroundColor: colors.lightBadges3Success,
          outline: `1px solid ${colors.lightBadges3Success}`,
          opacity: 0.5,
        },

        '&.more .moreTag.UPCOMING::before': {
          opacity: 0.5,
        },

        '&.more .moreTag.VISIT .displayName': {
          color: colors.visit,
        },

        '&.preview': {
          backgroundColor: colors.calcite,
        },

        '&.preview .moreTag': {
          position: 'relative',
          padding: '10px 0',
        },

        '&.preview .moreTag .MuiTypography-root': {
          fontSize: 10,
          fontWeight: 600,
        },

        '&.preview .moreTag + .moreTag': {
          borderTop: 'solid 1px #e0e0e0',
        },

        '&.preview .arrow::before': {
          backgroundColor: colors.calcite,
          border: `1px solid ${colors.calcite}`,
        },

        '&.preview .VISIT .displayName': {
          color: colors.visit,
        },

        '&.preview .DEVICE .displayName': {
          color: colors.wear,
        },

        '&.preview .MOBILE .displayName': {
          color: colors.task,
        },
      },

      arrow: {
        color: colors.darkBlueGrey,

        '&.popover': {
          color: themeSettings.palette.background.paper,
        },

        '&::before': {
          border: '1px solid rgba(16, 22, 26, 0.2)',
          boxShadow:
            '0 8px 24px 0 rgba(16, 22, 26, 0.2), 0 2px 4px 0 rgba(16, 22, 26, 0.2), 0 0 0 1px rgba(16, 22, 26, 0.1)',
        },

        '&.moreArrow::before': {
          backgroundColor: colors.calcite,
          border: `1px solid ${colors.calcite}`,
        },
      },
    },
  },
});
